import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Row,
  FloatingLabel,
  Card,
  Table,
} from "react-bootstrap";

interface EditScheduleModalProps {
  inputs: any[];
  setInputs: (inputs: any[]) => void;
}

const TitleComponent = ({ inputs, setInputs }: EditScheduleModalProps) => {
  const [selectedOption, setSelectedOption] = useState<{
    [key: number]: string;
  }>({});
  const [isChecked, setIsChecked] = useState<{ [key: number]: boolean }>({});
  const [inputsResult, setInputsResult] = useState<any[][]>([]);

  const handleAddInput = (inputIndex: number) => {
    const newInputsResult = [...inputsResult];
    if (!newInputsResult[inputIndex]) {
      newInputsResult[inputIndex] = [];
    }
    newInputsResult[inputIndex].push({ id: Math.random(), value: "" });
    setInputsResult(newInputsResult);
    updateComplexInputResults(inputIndex, newInputsResult[inputIndex]);
  };

  const handleInputResultChange = (
    inputIndex: number,
    resultIndex: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newInputsResult = [...inputsResult];
    newInputsResult[inputIndex][resultIndex].value = event.target.value;
    setInputsResult(newInputsResult);
    updateComplexInputResults(inputIndex, newInputsResult[inputIndex]);
  };

  const handleRemoveResult = (inputIndex: number, id: any) => {
    const newInputsResult = [...inputsResult];
    newInputsResult[inputIndex] = newInputsResult[inputIndex].filter(
      (input) => input.id !== id
    );
    setInputsResult(newInputsResult);
    updateComplexInputResults(inputIndex, newInputsResult[inputIndex]);
  };

  const updateComplexInputResults = (inputIndex: number, results: any[]) => {
    const newInputs = [...inputs];
    if (newInputs[inputIndex].type === "complex") {
      newInputs[inputIndex].result_options = results;
      setInputs(newInputs);
    }
  };

  const handleAddTextTypeInput = () => {
    const newInput = {
      id: inputs.length,
      type: "simpleText",
      value: "",
    };
    setInputs([...inputs, newInput]);
  };

  const handleAddComplexInput = () => {
    const newInput = {
      id: inputs.length,
      type: "complex",
      name: "",
      unit: "",
      result_options: [],
      result: [],
      referenceRange: "",
      separated: 0,
      price: 0,
      status: false,
    };
    setInputs([...inputs, newInput]);
    setInputsResult([...inputsResult, []]);
  };

  const handleRemoveInput = (id: number) => {
    const inputIndex = inputs.findIndex((input) => input.id === id);
    setInputs(inputs.filter((input) => input.id !== id));
    if (inputs[inputIndex].type === "complex") {
      setInputsResult(inputsResult.filter((_, index) => index !== inputIndex));
    }
  };

  const handleInputChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value, type } = event.target;
    const newValue =
      type === "checkbox" ? (event.target as HTMLInputElement).checked : value;
    const newInputs = inputs.map((input) => {
      if (input.id === id) {
        if (type === "checkbox") {
          if (name === "separated" || name === "status") {
            return { ...input, [name]: newValue ? 1 : 0 };
          }
          return { ...input, [name]: newValue };
        } else {
          return { ...input, [name]: value };
        }
      }
      return input;
    });
    setInputs(newInputs);
  };

  const handleRadioChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const newInputs = [...inputs];
    const newInput = newInputs.find((input) => input.id === id);
    if (newInput) {
      if (value === "text") {
        newInput.result = ["text"];
        newInput.result_options = []; // Empty result_options when "Text" is selected
      } else if (value === "select") {
        newInput.result = ["select"];
        // Logic to handle "Select" option, if needed
      } else {
        newInput.result = null; // Empty result array if neither "Text" nor "Select" is selected
        newInput.result_options = null; // Empty result_options when neither "Text" nor "Select" is selected
      }
      setInputs(newInputs);
    }
    setSelectedOption((prev) => ({ ...prev, [id]: value }));
  };

  const handleCheckboxChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setIsChecked((prev) => ({ ...prev, [id]: checked }));
  };

  return (
    <Row className="mt-3 ">
      <Col xs={12}>
        <Card>
          <Card.Body>
            <Row className="align-items-center mb-3">
              <Col sm="auto">
                <label className="fw-bold d-inline-flex me-2">
                  Test Components
                </label>
              </Col>
              <Col className="text-sm-end mt-sm-0 mt-2">
                <Button onClick={handleAddTextTypeInput} className="me-1">
                  <i className="uil-plus me-1"></i>Title
                </Button>
                <Button onClick={handleAddComplexInput}>
                  <i className="uil-plus me-1"></i>Components
                </Button>
              </Col>
            </Row>
            <div>
              {inputs.map((input, index) =>
                input.type === "complex" ? (
                  <Table striped bordered hover responsive key={index}>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Unit</th>
                        <th>Result</th>
                        <th>Reference Range</th>
                        <th>Separated</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <Form.Control
                            type="text"
                            name="name"
                            value={input?.name}
                            onChange={(e) => handleInputChange(input.id, e)}
                          />
                        </td>
                        <td>
                          <Form.Control
                            type="text"
                            name="unit"
                            value={input.unit}
                            onChange={(e) => handleInputChange(input.id, e)}
                          />
                        </td>
                        <td>
                          <div className="mb-3">
                            <Form.Check
                              type="radio"
                              id={`text-${index}`}
                              label="Text"
                              name={`result_options-${index}`}
                              value="text"
                              checked={selectedOption[input.id] === "text"}
                              onChange={(e) => handleRadioChange(input.id, e)}
                            />
                          </div>
                          <div className="mb-3">
                            <Form.Check
                              type="radio"
                              id={`select-${index}`}
                              label="Select"
                              name={`result_options-${index}`}
                              value="select"
                              checked={selectedOption[input.id] === "select"}
                              onChange={(e) => handleRadioChange(input.id, e)}
                            />
                          </div>

                          {selectedOption[input.id] === "select" && (
                            <Card>
                              <Card.Body>
                                <Row>
                                  <Col xs={11}>
                                    <div className="page-title-box">
                                      <h4 className="page-title">
                                        Add Options
                                      </h4>
                                      <div className="page-title-right">
                                        <div className="mt-2 mt-md-0">
                                          <Button
                                            variant="primary"
                                            onClick={() =>
                                              handleAddInput(index)
                                            }
                                          >
                                            <i className="uil-plus"></i>
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <div>
                                  {inputsResult[index]?.map(
                                    (result, resultIndex) => (
                                      <Row
                                        key={resultIndex}
                                        className="align-items-center mb-3 grid row"
                                      >
                                        <Col xs={9}>
                                          <Form.Group
                                            controlId={`validationInput${resultIndex}`}
                                          >
                                            <Form.Control
                                              required
                                              type="text"
                                              placeholder={`Enter Option ${
                                                resultIndex + 1
                                              }`}
                                              value={result.value}
                                              onChange={(e) =>
                                                handleInputResultChange(
                                                  index,
                                                  resultIndex,
                                                  e
                                                )
                                              }
                                            />
                                          </Form.Group>
                                        </Col>
                                        <Col xs={3}>
                                          <Button
                                            variant="danger"
                                            onClick={() =>
                                              handleRemoveResult(
                                                index,
                                                result.id
                                              )
                                            }
                                          >
                                            <i className="bi bi-trash fs-15"></i>
                                          </Button>
                                        </Col>
                                      </Row>
                                    )
                                  )}
                                </div>
                              </Card.Body>
                            </Card>
                          )}
                        </td>
                        <td>
                          <FloatingLabel controlId="floatingTextarea2" label="">
                            <Form.Control
                              as="textarea"
                              name="referenceRange"
                              value={input.referenceRange}
                              placeholder="Leave a comment here"
                              style={{ height: "100px" }}
                              onChange={(e) => handleInputChange(input.id, e)}
                            />
                          </FloatingLabel>
                        </td>
                        <td>
                          <Form.Check
                            type="checkbox"
                            name="separated"
                            checked={input.separated}
                            onChange={(e) => {
                              handleCheckboxChange(input.id, e);
                              handleInputChange(input.id, e);
                            }}
                          />
                          {isChecked[input.id] && (
                            <Card className="mt-3">
                              <h5>Price</h5>
                              <Form.Control
                                type="number"
                                name="price"
                                value={input.price}
                                onChange={(e) => handleInputChange(input.id, e)}
                              />
                            </Card>
                          )}
                        </td>

                        <td>
                          <Form.Check
                            type="checkbox"
                            name="status"
                            checked={input.status === 1}
                            onChange={(e) => handleInputChange(input.id, e)}
                          />
                        </td>
                        <td>
                          <Button
                            variant="danger"
                            onClick={() => handleRemoveInput(input.id)}
                          >
                            <i className="bi bi-trash fs-15"></i>
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                ) : (
                  <Row key={index} className="align-items-center mb-3">
                    <Col md={11}>
                      <Form.Group controlId={`simpleInput${index}`}>
                        <Form.Control
                          required
                          type="text"
                          placeholder={`Enter Title ${index + 1}`}
                          name="value"
                          value={input.value}
                          onChange={(e) => handleInputChange(input.id, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Button
                        variant="danger"
                        onClick={() => handleRemoveInput(input.id)}
                      >
                        <i className="bi bi-trash fs-15"></i>
                      </Button>
                    </Col>
                  </Row>
                )
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default TitleComponent;
