import { useMutation, useQueryClient } from "@tanstack/react-query";

import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { createItem } from "../../../utils/apiService";
import { FormInput } from "../../../components";

function Example({ lgShow, setLgShow }: any) {
  // form methods
  const methods = useForm();
  //   {
  //   defaultValues: isEditable ? EventData : null,
  //   resolver: schemaResolver,
  // }
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  // my code
  const queryClient = useQueryClient();
  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) => createItem("/patients", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patients"] });
    },
  });
  const formatDateForMySQL = (date: any) => {
    const yyyy = date.getUTCFullYear();
    const mm = String(date.getUTCMonth() + 1).padStart(2, "0");
    const dd = String(date.getUTCDate() + 1).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  };

  // handle form submission
  const onSubmitEvent = (data: any, event: any) => {
    const newData = { ...data, dob: formatDateForMySQL(new Date(data?.dob)) };

    CreateMutate(newData);
    // console.log(newData);
    event.preventDefault();
    event.stopPropagation();

    // onAddEvent(data);
  };
  return (
    <>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Create Patient
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {" "}
          <form
            noValidate
            name="chat-form"
            id="chat-form"
            onSubmit={handleSubmit(onSubmitEvent)}
          >
            <Row>
              <Col lg={12}>
                <FormInput
                  type="text"
                  label="Patient's Name"
                  name="name"
                  // defaultValue={patient?.name}
                  className="form-control"
                  placeholder="Insert Patient's Name"
                  containerClass={"mb-3"}
                  register={register}
                  key="name"
                  errors={errors}
                  control={control}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormInput
                  type="text"
                  label="Email Address"
                  name="email"
                  // defaultValue={patient?.email}
                  className="form-control"
                  placeholder="Insert Patient's Email"
                  containerClass={"mb-3"}
                  register={register}
                  key="email"
                  errors={errors}
                  control={control}
                />
              </Col>
              <Col lg={6}>
                <FormInput
                  type="text"
                  label="Phone"
                  name="phone"
                  // defaultValue={patient?.phone}
                  className="form-control"
                  placeholder="Insert Phone Number"
                  containerClass={"mb-3"}
                  register={register}
                  key="phone"
                  errors={errors}
                  control={control}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <label className="form-label">Gender</label>
                <div>
                  <label className="form-check form-check-inline">
                    <input
                      // defaultChecked={patient?.gender === "male"}
                      type="radio"
                      className="form-check-input"
                      // name="gender"
                      value="male"
                      {...register("gender")}
                    />
                    Male
                  </label>
                  <label className="form-check form-check-inline">
                    <input
                      // defaultChecked={patient?.gender === "female"}
                      type="radio"
                      className="form-check-input"
                      value="female"
                      {...register("gender")}
                    />
                    Female
                  </label>
                </div>
                {errors.gender && (
                  <div className="invalid-feedback d-block">
                    {errors.gender.message}
                  </div>
                )}
              </Col>
              <Col lg={6}>
                <FormInput
                  type="date"
                  label="Date of Birth"
                  name="dob"
                  // defaultValue={patient?.dob}
                  className="form-control"
                  placeholder="Insert Date of Birth"
                  containerClass={"mb-3"}
                  register={register}
                  key="dob"
                  errors={errors}
                  control={control}
                />
              </Col>
            </Row>
            {/* <Row>
            <Col lg={6}>
              <FormInput
                type="number"
                label="Total"
                name="total"
                defaultValue={patient?.total}
                className="form-control"
                placeholder="Insert Total Amount"
                containerClass={"mb-3"}
                register={register}
                key="total"
                errors={errors}
                control={control}
              />
            </Col>
            <Col lg={6}>
              <FormInput
                type="number"
                label="Paid"
                name="paid"
                defaultValue={patient?.paid}
                className="form-control"
                placeholder="Insert Paid Amount"
                containerClass={"mb-3"}
                register={register}
                key="paid"
                errors={errors}
                control={control}
              />
            </Col>
          </Row> */}

            <Row>
              <Col lg={12}>
                <FormInput
                  type="text"
                  label="Address"
                  name="address"
                  // defaultValue={patient?.address}
                  className="form-control"
                  placeholder="Insert Address"
                  containerClass={"mb-3"}
                  register={register}
                  key="address"
                  errors={errors}
                  control={control}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col xs={4}>
              {isEditable ? (
                <Button
                  disabled={isPending}
                  variant="danger"
                  onClick={() => mutate(patient?.id)}
                >
                  {isError ? "Failed" : isPending ? "Deleting" : "Delete"}
                </Button>
              ) : null}
            </Col> */}
              <Col xs={8} className="text-end">
                {/* <Button className="btn btn-light me-1" onClick={handleClose}>
                Close
              </Button> */}
                <Button
                  disabled={isCreatePending}
                  variant="success"
                  type="submit"
                  className="btn btn-success"
                >
                  {isCreateError
                    ? "Failed"
                    : isCreatePending
                    ? "Creating..."
                    : "Create"}
                </Button>
              </Col>
            </Row>
          </form>
        </Modal.Body>
        {/* <Modal.Body>
          <Row>
            <Col className="col-4">
              {" "}
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="bi bi-person"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Username"
                  aria-label="Client Name"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
            <Col className="col-4">
              {" "}
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="bi bi-envelope"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Email Address"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
            <Col className="col-4">
              {" "}
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="bi bi-telephone"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Phone"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              {" "}
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="bi bi-geo-alt"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Address"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
            <Col className="col-4">
              {" "}
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="bi bi-gender-male"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Select Gender"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
            <Col className="col-4">
              {" "}
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">
                  <i className="bi bi-calendar"></i>
                </InputGroup.Text>
                <Form.Control
                  placeholder="Date OF Birth"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
            </Col>
          </Row>
          <Button variant="primary">Save</Button>{" "}
        </Modal.Body> */}
      </Modal>
    </>
  );
}

export default Example;
