import React from "react";

// components
import { Card, Form, InputGroup } from "react-bootstrap";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchItems, updateItem } from "../../../../utils/apiService";
import TableSkeleton from "../../../../components/TableSkeleton";

// Define interface for CulturePrice
interface CulturePriceType {
  id: number;
  // test: string;
  price: number;
}

const columns = (updatePrice: (newPrice: number, id: number) => void) => [
  {
    Header: "Test",
    accessor: "culture_name",
    sort: true,
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ value, row }: any) => (
      <InputGroup>
        <Form.Control
          defaultValue={value}
          type="number"
          style={{ width: "25px" }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              const newPrice = parseFloat((e.target as HTMLInputElement).value);
              const id = row.original.id;
              updatePrice(newPrice, id);
            }
          }}
          aria-label="Dollar amount (with dot and two decimal places)"
        />
        <InputGroup.Text>USD</InputGroup.Text>
      </InputGroup>
    ),
  },
];

const CulturePrice = () => {
  const queryClient = useQueryClient();

  const {
    mutate: upDateMutate,
    // isError: isUpdateError,
    isPending: isUpdatePending,
  } = useMutation({
    mutationFn: (item: CulturePriceType) =>
      updateItem("/culture-prices", item.id, item),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["culture-prices"] });
    },
  });

  const updatePrice = (newPrice: number, id: number) => {
    const updatedItem: CulturePriceType = {
      id: id,
      price: newPrice,
    };
    console.log(updatedItem);

    upDateMutate(updatedItem);
  };

  const {
    data: culturePrices,
    isError,
    isLoading,
  } = useQuery<CulturePriceType[]>({
    queryKey: ["culture-prices"],
    queryFn: () => fetchItems("/culture-prices"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  console.log(culturePrices);

  if (isError) {
    return <div>Something Went Wrong!</div>;
  }

  const sizePerPageList = [
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: culturePrices?.length ?? 0, // Ensure it's not null or undefined
    },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Culture", path: "/apps/culture", active: true },
        ]}
        title={"Culture Table"}
      />

      <Card>
        <Card.Body>
          {isLoading || isUpdatePending ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns(updatePrice)}
              data={culturePrices as any}
              pageSize={25}
              sizePerPageList={sizePerPageList}
              isSortable={true}
              pagination={true}
              isSearchable={true}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default CulturePrice;
