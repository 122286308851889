import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import PageTitle from "../../../components/PageTitle";
import { fetchItems } from "../../../utils/apiService";
import { useFormContext } from "react-hook-form";

interface Test {
  id: number;
  price: number;
}

interface Culture {
  id: number;
  price: number;
}

interface TestCultureProps {
  selectedTests: Test[];
  selectedCultures: Culture[];
  discount: any;
  setDiscount: any;
  setSelectContract: any;
  selectedContract: any;
}

const Receipt: React.FC<TestCultureProps> = ({
  selectedCultures,
  selectedTests,
  setDiscount,
  discount,
  selectedContract,
  setSelectContract,
}) => {
  const { register } = useFormContext();
  const [subtotal, setSubtotal] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const { data: contracts } = useQuery({
    queryKey: ["contracts"],
    queryFn: () => fetchItems("/contracts"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const calcSubtotal = () => {
      const culturesTotal = selectedCultures.reduce(
        (sum, culture) => sum + parseFloat(culture.price as any),
        0
      );
      const testsTotal = selectedTests.reduce(
        (sum, test) => sum + parseFloat(test.price as any),
        0
      );
      setSubtotal(culturesTotal + testsTotal);
    };

    calcSubtotal();
  }, [selectedCultures, selectedTests]);

  useEffect(() => {
    const calcTotal = () => {
      const discountAmount = (subtotal * discount) / 100;
      setTotal(subtotal - discountAmount);
    };

    calcTotal();
  }, [subtotal, discount]);

  const handleContract = (id: any) => {
    const data = contracts?.data?.filter(
      (item: any) => item?.id === Number(id)
    );
    setSelectContract(data[0]);
    if (data[0]) {
      setDiscount(Number(data[0].discount));
    } else {
      setDiscount(0);
    }
  };

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setDiscount(isNaN(value) ? 0 : value);
    setSelectContract(null); // Reset contract selection when discount is manually changed
  };

  const handleClear = () => {
    setDiscount(0);
    setSelectContract(null);
  };

  return (
    <>
      <Card>
        <Card.Body>
          <PageTitle
            breadCrumbItems={[
              { label: "Receipt", path: "/apps/invoice/add", active: true },
            ]}
            title={"Receipt"}
          />
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                <Col md={8} className="mx-auto">
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={2} htmlFor="subtotal">
                      Subtotal
                    </Form.Label>
                    <Col lg={10}>
                      <Form.Control
                        type="text"
                        id="subtotal"
                        disabled
                        value={subtotal.toFixed(2)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={2}>
                      Contract
                    </Form.Label>
                    <Col>
                      <Form.Group className="position-relative">
                        <div className="d-flex">
                          <Form.Control
                            as="select"
                            value={selectedContract?.id || ""}
                            onChange={(e) => handleContract(e.target.value)}
                            className="position-relative"
                          >
                            <option value="">Choose...</option>
                            {contracts?.data?.map((contract: any) => (
                              <option key={contract?.id} value={contract?.id}>
                                {`${contract?.title} (${contract?.discount} %)`}
                              </option>
                            ))}
                          </Form.Control>
                          <i className="icon position-absolute end-0 top-50 pe-2"></i>
                        </div>
                      </Form.Group>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={2} htmlFor="discount">
                      Discount (%)
                    </Form.Label>
                    <Col lg={8}>
                      <Form.Control
                        type="number"
                        id="discount"
                        value={discount}
                        {...register("discount", { required: true })}
                        onChange={handleDiscountChange}
                      />
                    </Col>
                    <Col lg={2}>
                      <Button variant="danger" onClick={handleClear}>
                        Clear
                      </Button>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={2} htmlFor="total">
                      Total
                    </Form.Label>
                    <Col lg={10}>
                      <Form.Control
                        type="text"
                        id="total"
                        disabled
                        value={total.toFixed(2)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column lg={2} htmlFor="paid">
                      paid amount
                    </Form.Label>
                    <Col lg={10}>
                      <Form.Control
                        type="number"
                        id="paid"
                        {...register("paid", { required: true })}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Card.Body>
      </Card>
    </>
  );
};

export default Receipt;
