const API_BASE_URL = "https://api.sohabagcluster.com/api";

// Define types for your data
export interface Records {
  id: number;
  discount: string;
  name: string;
  company: string;
  phone: string;
  code: string;
  email: string;
  total: string;
  paid: string;
  due: string;
  address: string;
  date: string;
  role: string;
}

const handleResponse = async (response: Response) => {
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error || "Something went wrong");
  }

  // Check if the response has content
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return response.json();
  }

  // If no content, resolve with null
  return null;
};

export const fetchItems = async (endPoint: string): Promise<any> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endPoint}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error fetching items:", error);
    throw new Error("Could not fetch items.");
  }
};

export const fetchItem = async (endPoint: string, id: any): Promise<any> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endPoint}/${id}`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`Error fetching item with id ${id}:`, error);
    throw new Error(`Could not fetch item with id ${id}.`);
  }
};

export const createItem = async (endPoint: string, data: any): Promise<any> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error creating item:", error);
    throw new Error("Could not create item.");
  }
};

export const creaUpdateteItem = async (
  endPoint: string,
  id: number,
  data: any
): Promise<any> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endPoint}/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    console.error("Error creating item:", error);
    throw new Error("Could not create item.");
  }
};

export const updateItem = async (
  endPoint: string,
  id: number,
  data: any
): Promise<any> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endPoint}/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`Error updating item with id ${id}:`, error);
    throw new Error(`Could not update item with id ${id}.`);
  }
};

export const deleteItem = async (endPoint: string): Promise<void> => {
  try {
    const response = await fetch(`${API_BASE_URL}${endPoint}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    await handleResponse(response);
  } catch (error) {
    console.error(`Error deleting item at ${endPoint}:`, error);
    throw new Error("Could not delete item.");
  }
};
