import React from "react";
import { Card, Form, InputGroup } from "react-bootstrap";
import PageTitle from "../../../../components/PageTitle";
import Table from "../../../../components/Table";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchItems, updateItem } from "../../../../utils/apiService";
import TableSkeleton from "../../../../components/TableSkeleton";

interface TestPriceType {
  id: number;
  // test: string;
  price: number;
}

const columns = (updatePrice: (newPrice: number, id: number) => void) => [
  {
    Header: "Test",
    accessor: "test_name",
    sort: true,
  },
  {
    Header: "Price",
    accessor: "price",
    Cell: ({ value, row }: any) => (
      <InputGroup>
        <Form.Control
          defaultValue={value}
          type="number"
          style={{ width: "25px" }}
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              const newPrice = parseFloat((e.target as HTMLInputElement).value);
              const id = row.original.id;
              updatePrice(newPrice, id);
            }
          }}
          aria-label="Dollar amount (with dot and two decimal places)"
        />
        <InputGroup.Text>USD</InputGroup.Text>
      </InputGroup>
    ),
  },
];

const TestPrice = () => {
  const queryClient = useQueryClient();

  const { mutate: updateMutate, isPending: isUpdatePending } = useMutation({
    mutationFn: (item: TestPriceType) =>
      updateItem("/test-prices", item.id, item),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["test-prices"] });
    },
  });

  const updatePrice = (newPrice: number, id: number) => {
    const updatedItem: TestPriceType = {
      id: id,

      price: newPrice,
    };
    // console.log(updatedItem);

    updateMutate(updatedItem);
  };

  const {
    data: testPrices,
    isError,
    isLoading,
  } = useQuery<TestPriceType[]>({
    queryKey: ["test-prices"],
    queryFn: () => fetchItems("/test-prices"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  if (isError) {
    return <div>Something Went Wrong!</div>;
  }
  // console.log(testPrices);

  const sizePerPageList = [
    { text: "25", value: 25 },
    { text: "All", value: testPrices?.length ?? 0 },
  ];

  return (
    <>
      <PageTitle
        breadCrumbItems={[{ label: "Test", path: "/apps/test", active: true }]}
        title={"Tests Table"}
      />
      <Card>
        <Card.Body>
          {isLoading || isUpdatePending ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns(updatePrice)}
              data={testPrices as any}
              pageSize={25}
              sizePerPageList={sizePerPageList}
              isSortable={true}
              pagination={true}
              isSearchable={true}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default TestPrice;
