import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import records from "./data";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createItem, fetchItem, updateItem } from "../../../utils/apiService";

const NormalFormValidation = ({ formData }: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      shortcut: "",
      commercial_name: "",
    },
  });

  useEffect(() => {
    if (formData) {
      setValue("name", formData.name);
      setValue("shortcut", formData.shortcut);
      setValue("commercial_name", formData.commercial_name);
    }
  }, [formData, setValue]);

  const queryClient = useQueryClient();
  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) => createItem("/antibiotics", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["antibiotics"] });
    },
  });

  // update
  const {
    mutate: upDateMutate,
    isError: isUpdateError,
    isPending: isUpdatePending,
  } = useMutation({
    mutationFn: (item) => updateItem("/antibiotics", formData?.id, item),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["antibiotics"] });
    },
  });

  const onSubmit = (data: any) => {
    console.log("Form  ", data);
    if (formData) {
      upDateMutate(data);
      return;
    } else {
      CreateMutate(data);
      return;
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="align-items-center">
              <Col className="mb-3">
                <Form.Group
                  className="position-relative"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter name"
                    {...register("name", { required: "Name is required" })}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="mb-3">
                <Form.Group
                  className="position-relative"
                  controlId="validationTooltip02"
                >
                  <Form.Label>Shortcut</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter shortcut"
                    {...register("shortcut", {
                      required: "Shortcut is required",
                    })}
                    isInvalid={!!errors.shortcut}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.shortcut?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="mb-3">
                <Form.Group
                  className="position-relative"
                  controlId="validationTooltip03"
                >
                  <Form.Label>Commercial Name</Form.Label>
                  <Form.Control
                    as="textarea"
                    style={{ height: "100px" }}
                    placeholder="Enter commercial name"
                    {...register("commercial_name", {
                      required: "Commercial name is required",
                    })}
                    isInvalid={!!errors.commercial_name}
                  />
                  <Form.Control.Feedback type="invalid" tooltip>
                    {errors.commercial_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {formData ? (
              <Button
                disabled={isUpdatePending}
                variant="primary"
                type="submit"
              >
                {isUpdateError
                  ? "Failed"
                  : isUpdatePending
                  ? "Updating"
                  : "Update"}{" "}
              </Button>
            ) : (
              <Button
                disabled={isCreatePending}
                variant="primary"
                type="submit"
              >
                {isCreateError
                  ? "Failed"
                  : isCreatePending
                  ? "Creating..."
                  : "Create"}
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

const AddAntibiotics = () => {
  const { name } = useParams();
  const [editData, setEditData] = useState<any>(null);

  const {
    data: antibiotic,
    // isError,
    isLoading,
  } = useQuery({
    queryKey: [`antibiotics/${name}`],
    queryFn: () => fetchItem("/antibiotics", Number(name)),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!name,
  });

  useEffect(() => {
    if (name && antibiotic) {
      setEditData(antibiotic);
    }
  }, [antibiotic, name]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Antibiotics", path: "/apps/antibiotics" },
          { label: "Create", path: "/apps/antibiotics/add", active: true },
        ]}
        title={"Create Antibiotic"}
      />
      <Row>
        <Col lg={12}>
          {isLoading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <NormalFormValidation formData={editData} />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddAntibiotics;
