import React, { useState } from "react";
import { Accordion, Tab, Nav, Table, Button, Spinner } from "react-bootstrap";
import "./TestsComponent.css";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { creaUpdateteItem } from "../../../utils/apiService";

interface TestsComponentProps {
  testData: any; // Array of tests
}

const TestsComponent: React.FC<TestsComponentProps> = ({ testData }) => {
  const [activeKey, setActiveKey] = useState("0");
  const [tabData, setTabData] = useState<any>({});
  const queryClient = useQueryClient();

  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) =>
      creaUpdateteItem("/reports/results", testData?.id, newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reports"] });
    },
  });
  // console.log(testData?.tests);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const activeTest = testData?.tests?.[parseInt(activeKey)];
    if (!activeTest) return; // If no active test, do nothing

    const formData = {
      test_id: activeTest.id,
      components: activeTest.components.map((component: any) => ({
        id: component.id,
        result: tabData[activeTest.id]?.resultText?.[component.id] || "",
        status: tabData[activeTest.id]?.status?.[component.id] || "",
      })),
      comment: tabData[activeTest.id]?.comment || "",
    };

    console.log("Form Data:", formData);
    CreateMutate(formData as any);
  };

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    testId: string
  ) => {
    setTabData((prevTabData: { [x: string]: any }) => ({
      ...prevTabData,
      [testId]: {
        ...prevTabData[testId],
        comment: event.target.value,
      },
    }));
  };

  const handleResultTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    testId: string,
    componentId: string
  ) => {
    setTabData((prevTabData: { [x: string]: { resultText: any } }) => ({
      ...prevTabData,
      [testId]: {
        ...prevTabData[testId],
        resultText: {
          ...prevTabData[testId]?.resultText,
          [componentId]: event.target.value,
        },
      },
    }));
  };

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    testId: string,
    componentId: string
  ) => {
    setTabData((prevTabData: { [x: string]: { status: any } }) => ({
      ...prevTabData,
      [testId]: {
        ...prevTabData[testId],
        status: {
          ...prevTabData[testId]?.status,
          [componentId]: event.target.value,
        },
      },
    }));
  };

  return (
    <Accordion defaultActiveKey="0" className="custom-accordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Tests</Accordion.Header>
        <Accordion.Body>
          <form onSubmit={handleSubmit}>
            <Tab.Container
              defaultActiveKey="0"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k || "0")}
            >
              <Nav variant="tabs" className="custom-tabs">
                {testData?.tests?.map((test: any, testIndex: number) => (
                  <Nav.Item key={test.id}>
                    <Nav.Link eventKey={testIndex.toString()}>
                      {test.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content>
                {testData?.tests?.map((test: any, testIndex: number) => (
                  <Tab.Pane eventKey={testIndex.toString()} key={test.id}>
                    <Table striped bordered hover className="custom-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Unit</th>
                          <th>Reference Range</th>
                          <th>Result</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {test.components.map((component: any) => (
                          <tr key={component.id}>
                            <td>{component.name}</td>
                            {component?.title === "1" ? (
                              <td colSpan={4}></td>
                            ) : (
                              <>
                                <td>{component.unit}</td>
                                <td>{component.reference_range}</td>
                                <td>
                                  {component?.type === "select" ? (
                                    <select
                                      className="form-control"
                                      onChange={(e) =>
                                        handleResultTextChange(
                                          e,
                                          test.id.toString(),
                                          component.id.toString()
                                        )
                                      }
                                      value={
                                        tabData[test.id]?.resultText?.[
                                          component.id
                                        ] || ""
                                      }
                                    >
                                      <option value={""}>choose...</option>
                                      {component?.result_options.map(
                                        (option: any, index: number) => (
                                          <option key={index} value={option}>
                                            {option}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  ) : (
                                    <input
                                      className="form-control"
                                      onChange={(e) =>
                                        handleResultTextChange(
                                          e,
                                          test.id.toString(),
                                          component.id.toString()
                                        )
                                      }
                                      type="text"
                                      placeholder="result"
                                      value={
                                        tabData[test.id]?.resultText?.[
                                          component.id
                                        ] || ""
                                      }
                                    />
                                  )}
                                </td>
                                <td>
                                  <select
                                    onChange={(e) =>
                                      handleStatusChange(
                                        e,
                                        test.id.toString(),
                                        component.id.toString()
                                      )
                                    }
                                    className="form-control"
                                    value={
                                      tabData[test.id]?.status?.[
                                        component.id
                                      ] || ""
                                    }
                                  >
                                    <option value={""}>choose...</option>
                                    <option value={"high"}>High</option>
                                    <option value={"low"}>Low</option>
                                    <option value={"normal"}>Normal</option>
                                  </select>
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <textarea
                      className="form-control mt-3"
                      rows={4}
                      placeholder="Comments"
                      value={tabData[test.id]?.comment || ""}
                      onChange={(e) =>
                        handleCommentChange(e, test.id.toString())
                      }
                      name={`comments-${testIndex}`}
                    />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>

            <Button type="submit" className="mt-3" disabled={isCreatePending}>
              {isCreateError ? (
                "failed"
              ) : isCreatePending ? (
                <>
                  <Spinner animation="border" size="sm" /> Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default TestsComponent;
