import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form, FloatingLabel } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createItem, fetchItem, updateItem } from "../../../utils/apiService";
import { useForm } from "react-hook-form";

const NormalFormValidation = ({ formData }: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    // control,
    // formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      shortcut: "",
      // commercial_name: "",
      sample_type: "",
      price: "",
      comments: "",
    },
  });

  useEffect(() => {
    if (formData) {
      setValue("name", formData.name);
      setValue("shortcut", formData.shortcut);
      // setValue("commercial_name", formData.commercial_name);
      setValue("sample_type", formData.sample_type);
      setValue("price", formData.price);
      setValue("comments", formData.comments);
    }
  }, [formData, setValue]);

  const queryClient = useQueryClient();

  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) => createItem("/cultures", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cultures"] });
    },
  });

  // update
  const {
    mutate: upDateMutate,
    isError: isUpdateError,
    isPending: isUpdatePending,
  } = useMutation({
    mutationFn: (item) => updateItem("/cultures", formData?.id, item),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cultures"] });
    },
  });

  const onSubmit = (data: any) => {
    // console.log("Form  ", data);
    if (formData) {
      upDateMutate(data);
    } else {
      CreateMutate(data);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Row className="align-items-center">
            <Col sm={6} md={4} lg={3} className="mb-3">
              <Form.Group
                className="position-relative mb-3"
                controlId="validationTooltip01"
              >
                <Form.Label>First name</Form.Label>
                <Form.Control
                  {...register("name", { required: true })}
                  type="text"
                  placeholder="First name"
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={6} md={4} lg={3} className="mb-3">
              <Form.Group
                className="position-relative mb-3"
                controlId="validationTooltip02"
              >
                <Form.Label>Shortcut</Form.Label>
                <Form.Control
                  {...register("shortcut", { required: true })}
                  type="text"
                  placeholder="Shortcut"
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={6} md={4} lg={3} className="mb-3">
              <Form.Group
                className="position-relative mb-3"
                controlId="validationTooltip03"
              >
                <Form.Label>Sample Type</Form.Label>
                <Form.Control
                  {...register("sample_type", { required: true })}
                  type="text"
                  placeholder="Sample Type"
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col sm={6} md={4} lg={3} className="mb-3">
              <Form.Group
                className="position-relative mb-3"
                controlId="validationTooltip04"
              >
                <Form.Label>Price</Form.Label>
                <Form.Control
                  {...register("price", { required: true })}
                  type="number"
                  placeholder="Price"
                />
                <Form.Control.Feedback type="invalid" tooltip>
                  Looks good!
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md>
              <FloatingLabel
                className="mb-3"
                controlId="floatingTextarea2"
                label="comments"
              >
                <Form.Control
                  {...register("comments", { required: true })}
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                />
              </FloatingLabel>
            </Col>
          </Row>

          {formData ? (
            <Button disabled={isUpdatePending} variant="primary" type="submit">
              {isUpdateError
                ? "Failed"
                : isUpdatePending
                ? "Updating"
                : "Update"}{" "}
            </Button>
          ) : (
            <Button disabled={isCreatePending} variant="primary" type="submit">
              {isCreateError
                ? "Failed"
                : isCreatePending
                ? "Creating..."
                : "Create"}
            </Button>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

const AddCulture = () => {
  const { id } = useParams();
  const [editData, setEditData] = useState<any>(null);

  const { data: culture, isLoading } = useQuery({
    queryKey: [`cultures/${id}`],
    queryFn: () => fetchItem("/cultures", id),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  useEffect(() => {
    if (id && culture) {
      setEditData(culture);
    }
  }, [culture, id]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Test", path: "/apps/test" },
          { label: "Create", path: "/apps/test/add", active: true },
        ]}
        title={"CREATE"}
      />

      <Row>
        <Col lg={12}>
          {isLoading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <NormalFormValidation formData={editData} />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddCulture;
