import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
// import { records } from "./data";
import { useParams } from "react-router-dom";
import TitleComponent from "./TitleComponent";
import {
  createItem,
  fetchItem,
  fetchItems,
  updateItem,
} from "../../../utils/apiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

const NormalFormValidation = ({ formData }: any) => {
  const [inputs, setInputs] = useState<any>([]);
  console.log(inputs);

  // const formData? = formData?.length ? formData[0] : {};
  console.log(formData);

  const titleData =
    formData &&
    formData?.components?.filter((item: any) => item?.title === "1");

  const componentData =
    formData &&
    formData?.components?.filter((item: any) => item?.title === "0");
  // console.log("tit", componentData);

  const defaultValues = {
    category_id: formData?.category_id || "",
    name: formData?.name || "",
    shortcut: formData?.shortcut || "",
    sample_type: formData?.sample_type || "",
    price: formData?.price || "",
    precautions: formData?.precautions || "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues });

  useEffect(() => {
    // Check if formData is not null or undefined
    if (formData) {
      // Extract the values from formData
      const { category_id, name, shortcut, sample_type, price, precautions } =
        formData;

      // Set the values using setValue
      setValue("category_id", category_id || "");
      setValue("name", name || "");
      setValue("shortcut", shortcut || "");
      setValue("sample_type", sample_type || "");
      setValue("price", price || "");
      setValue("precautions", precautions || "");
    }
  }, [formData, setValue]);

  const [validated] = useState(false);
  const {
    data: categories,
    // isError,
    // isLoading,
  } = useQuery({
    queryKey: ["categories"],
    queryFn: () => fetchItems("/categories"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const flattenNestedValues = (nestedArray: any[]): (string[] | null)[] => {
    const result: (string[] | null)[] = [];

    const extractValues = (array: any[]) => {
      const nestedResult: string[] = [];
      array.forEach((item) => {
        if (Array.isArray(item)) {
          if (item.length === 0) {
            result.push(null); // Push null if the nested array is empty
          } else {
            result.push(item.map((innerItem) => innerItem.value)); // Extract values directly and push to result
          }
        } else if (item && typeof item === "object" && "value" in item) {
          nestedResult.push(item.value);
        }
      });
      if (nestedResult.length > 0) {
        result.push(nestedResult);
      }
      return nestedResult;
    };

    extractValues(nestedArray);

    return result;
  };

  function flattenArrays(arrays: any) {
    return [].concat(...arrays);
  }
  const queryClient = useQueryClient();
  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) => createItem("/tests", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tests"] });
    },
  });

  // update
  const {
    mutate: upDateMutate,
    isError: isUpdateError,
    isPending: isUpdatePending,
  } = useMutation({
    mutationFn: (item) => updateItem("/tests", formData?.id, item),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tests"] });
    },
  });
  const onSubmit = (data: any) => {
    const title = inputs
      .filter((item: any) => item?.type === "simpleText")
      .map((item: any) => item?.value);

    const component_name = inputs
      .filter((item: any) => item?.type === "complex")
      .map((item: any) => item?.name);

    const component_unit = inputs
      .filter((item: any) => item?.type === "complex")
      .map((item: any) => item?.unit);

    const res = inputs
      .filter((item: any) => item?.type === "complex")
      .map((item: any) => item?.result_options);
    const resultOp = flattenNestedValues(res);

    const results = inputs
      .filter((item: any) => item?.type === "complex")
      .map((item: any) => item?.result);
    const result = flattenArrays(results);

    const reference_range = inputs
      .filter((item: any) => item?.type === "complex")
      .map((item: any) => item?.referenceRange);

    const separated = inputs
      .filter((item: any) => item?.type === "complex")
      .map((item: any) => (item?.separated ? 1 : 0));

    const component_price = inputs
      .filter((item: any) => item?.type === "complex")
      .map((item: any) => Number(item?.price) || 0);

    const newData = {
      ...data,
      title,
      component_name,
      component_unit,
      reference_range,
      component_price,
      separated,
      result,
      result_options: resultOp,
      category_id: Number(data?.category_id),
    };
    console.log(newData);

    if (formData) {
      upDateMutate(newData);
      return;
    } else {
      CreateMutate(newData);
      return;
    }
  };
  console.log(inputs);

  return (
    <>
      <Card>
        <Card.Body>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="align-items-center">
              <Col sm={6} md={4} lg={3} className="mb-3">
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    {...register("name", {
                      required: "Name is required",
                    })}
                    type="text"
                    placeholder="Name"
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6} md={4} lg={3} className="mb-3">
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip02"
                >
                  <Form.Label>Shortcut</Form.Label>
                  <Form.Control
                    {...register("shortcut", {
                      required: "Shortcut is required",
                    })}
                    type="text"
                    placeholder="Shortcut"
                  />
                  {errors.shortcut && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.shortcut.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6} md={4} lg={3} className="mb-3">
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip03"
                >
                  <Form.Label>Sample Type</Form.Label>
                  <Form.Control
                    {...register("sample_type", {
                      required: "Sample Type is required",
                    })}
                    type="text"
                    placeholder="Sample Type"
                  />
                  {errors.sample_type && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.sample_type.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>

              <Col sm={6} md={4} lg={3} className="mb-3">
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip04"
                >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    {...register("price", {
                      required: "Price is required",
                      valueAsNumber: true,
                    })}
                    type="number"
                    placeholder="Price"
                  />
                  {errors.price && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.price.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={6} md={4} lg={3} className="mb-3">
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip05"
                >
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    {...register("category_id", {
                      required: "Category is required",
                    })}
                  >
                    <option value={""}>choose...</option>
                    {categories?.map((item: any) => (
                      <option value={item?.id}>{item?.name}</option>
                    ))}
                  </Form.Select>
                  {errors.category_id && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.category_id.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
              <Col md>
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip06"
                >
                  <Form.Label>Precautions</Form.Label>
                  <Form.Control
                    as="textarea"
                    {...register("precautions", {
                      required: "Precautions are required",
                    })}
                    placeholder="Precautions"
                    style={{ height: "100px" }}
                  />
                  {errors.precautions && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.precautions.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <TitleComponent inputs={inputs} setInputs={setInputs} />
            {formData ? (
              <Button
                disabled={isUpdatePending}
                variant="primary"
                type="submit"
              >
                {isUpdateError
                  ? "Failed"
                  : isUpdatePending
                  ? "Updating"
                  : "Update"}{" "}
              </Button>
            ) : (
              <Button
                disabled={isCreatePending}
                variant="primary"
                type="submit"
              >
                {isCreateError
                  ? "Failed"
                  : isCreatePending
                  ? "Creating..."
                  : "Create"}
              </Button>
            )}
          </Form>

          {/* <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="align-items-center">
              <Col sm={6} md={4} lg={3} className="mb-3">
                {" "}
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip01"
                >
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="First name"
                    defaultValue={formState.name}
                  />
                  <Form.Control.Feedback tooltip>
                    Looks good!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              <Col sm={6} md={4} lg={3} className="mb-3">
                {" "}
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Shortcut</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Shortcut"
                    defaultValue={formState.shortcut}
                  />
                  <Form.Control.Feedback tooltip>
                    Looks good!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={4} lg={3} className="mb-3">
                {" "}
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Sample Type</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Sample Type"
                    defaultValue={formState.sample_type}
                  />
                  <Form.Control.Feedback tooltip>
                    Looks good!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6} md={4} lg={3} className="mb-3">
                {" "}
                <Form.Group
                  className="position-relative mb-3"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="First name"
                    defaultValue={formState.price}
                  />
                  <Form.Control.Feedback tooltip>
                    Looks good!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md>
                <Form.Group
                  className="position-relative "
                  controlId="validationTooltip01"
                >
                  <Form.Label>Precautions</Form.Label>
                  <Form.Control
                    as="textarea"
                    required
                    placeholder="Precautions"
                    style={{ height: "100px" }}
                    defaultValue={formState.precautions}
                  />
                </Form.Group>
              </Col>
            </Row>
            <TitleComponent inputs={inputs} setInputs={setInputs} />
            <Button type="submit">{formData ? "Update" : "Submit"}</Button>
          </Form> */}
        </Card.Body>
      </Card>
    </>
  );
};

const AddTest = () => {
  const { shortcut } = useParams();
  const [editData, setEditData] = useState<any>(null);

  const {
    data: tests,
    // isError,
    isLoading,
  } = useQuery({
    queryKey: [`tests/${shortcut}`],
    queryFn: () => fetchItem("/tests", Number(shortcut)),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!shortcut,
  });
  // console.log("ed", editData, shortcut);

  useEffect(() => {
    if (tests && shortcut) {
      setEditData(tests);
    }
  }, [tests, shortcut]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Test", path: "/apps/test" },
          {
            label: `${shortcut ? "Update" : "Create"}`,
            path: shortcut ? "/apps/test/Update" : "/apps/test/add",
            active: true,
          },
        ]}
        title={`${shortcut ? "Update" : "Create"}`}
      />
      {isLoading ? (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Row>
          <Col lg={12}>
            <NormalFormValidation formData={editData} />
          </Col>
        </Row>
      )}

      {/* <Row>
        <Col>
          <TestComponent />
        </Col>
      </Row> */}
    </React.Fragment>
  );
};

export default AddTest;
