import { useQuery } from "@tanstack/react-query";
// import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { fetchItem } from "../../../utils/apiService";

// import { useReactToPrint } from "react-to-print";
import Invoice from "../../../components/Invoice";

const ShowRecept = () => {
  const { id } = useParams();
  const {
    data: invoice,
    isError,
    isLoading,
  } = useQuery({
    queryKey: [`invoices/${id}`],
    queryFn: () => fetchItem("/invoices", id),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  console.log(invoice);

  // const componentRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current as any,
  // });

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error loading invoice.</div>;
  }

  return (
    <div>
      <Invoice invoice={invoice} />
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        {/* <button
          onClick={handlePrint}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            marginRight: "10px",
          }}
        >
          Print
        </button>
        <button
          style={{
            padding: "10px 20px",
            backgroundColor: "#28a745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Submit
        </button> */}
      </div>
    </div>
  );
};

export default ShowRecept;
