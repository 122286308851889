import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useForm, FormProvider } from "react-hook-form";
import PageTitle from "../../../components/PageTitle";
import NormalFormValidation from "./NormalFormValidation";
import TestCulture from "./TestCulture";
import Receipt from "./Receipt";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createItem } from "../../../utils/apiService";
import { useNavigate } from "react-router-dom";

const AddInvoice = () => {
  const navigate=useNavigate();
  const [selectedTests, setSelectedTests] = useState<any[]>([]);
  const [selectedCultures, setSelectedCultures] = useState<any[]>([]);
  const [patientInfo, setPatientInfo] = useState<any>(null);
  const [discount, setDiscount] = useState<number>(0);
  const [selectedContract, setSelectContract] = useState<any>(null);
  const methods = useForm({ mode: "onBlur" });

  const queryClient = useQueryClient();
  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) => createItem("/invoices", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
      navigate("/apps/invoices");
    },
    onError(error, variables, context) {
      
    },
  });

  const onSubmit = (data: any) => {
    const newDate = new Date().toLocaleDateString();

    const newData = {
      ...data,
      tests: selectedTests,
      cultures: selectedCultures,
      patient_id: patientInfo?.id,
      date: newDate,
      contract_id: selectedContract?.id ? selectedContract?.id : null,
      discount,
      // status: "pending",
    };
    console.log(newData);
    CreateMutate(newData);
  };
  // console.log(selectedContract);

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Test", path: "/apps/test" },
          { label: "Create", path: "/apps/test/add", active: true },
        ]}
        title={"Create Invoice"}
      />
      <FormProvider {...methods}>
        <Form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
          <Row>
            <Col lg={12}>
              <NormalFormValidation
                setPatientInfo={setPatientInfo}
                patientInfo={patientInfo}
              />
              <TestCulture
                selectedTests={selectedTests}
                selectedCultures={selectedCultures}
                setSelectedCultures={setSelectedCultures}
                setSelectedTests={setSelectedTests}
              />
              <Receipt
                selectedContract={selectedContract}
                setSelectContract={setSelectContract}
                discount={discount}
                setDiscount={setDiscount}
                selectedTests={selectedTests}
                selectedCultures={selectedCultures}
              />
              <Button
                disabled={isCreatePending}
                variant="primary"
                type="submit"
              >
                {isCreateError
                  ? "Failed"
                  : isCreatePending
                  ? "Creating..."
                  : "Create"}
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddInvoice;
