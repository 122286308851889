import { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form, Modal } from "react-bootstrap";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import FeatherIcons from "feather-icons-react";
// import { branches, clientCodes, clientNames } from "./data";
import InfoModal from "./Modal";
import { createItem, fetchItems } from "../../../utils/apiService";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

interface patientInfoType {
  patientInfo: any;

  setPatientInfo: React.Dispatch<React.SetStateAction<any>>;
}

const NormalFormValidation: React.FC<patientInfoType> = ({
  setPatientInfo,
  patientInfo,
}) => {
  const [lgShow, setLgShow] = useState(false);
  const [lgShowDoctor, setLgShowDoctor] = useState(false);
  const {
    register,
    // handleSubmit,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setPatientInfo(patientInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo]);

  const queryClient = useQueryClient();

  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) => createItem("/doctors", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["doctors"] });
      setLgShowDoctor(false);
    },
  });
  const {
    data: branches,
    // isError,
    // isLoading,
  } = useQuery({
    queryKey: ["branches"],
    queryFn: () => fetchItems("/branches"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {
    data: doctors,
    // isError,
    // isLoading,
  } = useQuery({
    queryKey: ["doctors"],
    queryFn: () => fetchItems("/doctors"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {
    data: contracts,
    // isError,
    // isLoading,
  } = useQuery({
    queryKey: ["contracts"],
    queryFn: () => fetchItems("/contracts"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const {
    data: patients,
    // isError,
    // isLoading,
  } = useQuery({
    queryKey: ["patients"],
    queryFn: () => fetchItems("/patients"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const handlePatient = (id: any) => {
    const data = patients?.data?.filter((item: any) => item?.id === Number(id));
    setPatientInfo(data[0]);
  };

  const doctorFormMethods = useForm();
  const {
    handleSubmit: handleDoctorSubmit,
    register: registerDoctor,
    formState: { errors: doctorErrors },
  } = doctorFormMethods;

  const onSubmitDoctor = (data: any, event: any) => {
    // console.log("doc", data);
    CreateMutate(data);
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <Form.Group
                className="position-relative mb-3"
                controlId="branchSelection"
              >
                <Form.Label>Select Branch</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    as="select"
                    {...register("branch_id", { required: true })}
                    className="position-relative"
                  >
                    <option value="">Choose...</option>
                    {branches?.map((branch: any) => (
                      <option key={branch?.id} value={branch?.id}>
                        {branch?.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.branch && (
                    <span className="text-danger">This field is required</span>
                  )}
                  <i className="icon position-absolute end-0 top-50 pe-2">
                    <FeatherIcons icon="chevron-down" />
                  </i>
                </div>
              </Form.Group>
            </Col>
            {/* 
            <Col>
              <Form.Group
                className="position-relative mb-3"
                controlId="contract"
              >
                <Form.Label>Select Contract </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    as="select"
                    {...register("contract_id", { required: true })}
                    className="position-relative"
                  >
                    <option value="">Choose...</option>
                    {contracts?.data?.map((contract: any) => (
                      <option key={contract?.id} value={contract?.id}>
                        {`${contract?.title} (${contract?.discount} %)`}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.contract && (
                    <span className="text-danger">This field is required</span>
                  )}
                  <i className="icon position-absolute end-0 top-50 pe-2">
                    <FeatherIcons icon="chevron-down" />
                  </i>
                </div>
              </Form.Group>
            </Col> */}
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <Form.Label>Patient Info</Form.Label>
            <Button
              className="btn btn-warning btn-sm mb-2"
              onClick={() => setLgShow(true)}
            >
              <i className="bi bi-exclamation-triangle me-1"></i>Not Listed?
            </Button>
            <InfoModal lgShow={lgShow} setLgShow={setLgShow} />
          </div>
          <Row className="align-items-center">
            <hr />
            <Col>
              <Form.Group
                className="position-relative mb-3"
                controlId="clientCodeSelection"
              >
                <Form.Label>Code</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    onChange={(e) => handlePatient(e.target.value)}
                    as="select"
                    className="position-relative"
                    value={patientInfo?.id || ""}
                  >
                    <option value="">
                      {patientInfo?.code || "Choose Code"}
                    </option>
                    {patients?.data?.map((patient: any) => (
                      <option key={patient?.code} value={patient?.id}>
                        {patient?.code}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.clientCode && (
                    <span className="text-danger">This field is required</span>
                  )}
                  <i className="icon position-absolute end-0 top-50 pe-2">
                    <FeatherIcons icon="chevron-down" />
                  </i>
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="position-relative mb-3"
                controlId="clientNameSelection"
              >
                <Form.Label>Name</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    as="select"
                    onChange={(e) => handlePatient(e.target.value)}
                    className="position-relative"
                    value={patientInfo?.id || ""}
                  >
                    <option value="">{patientInfo?.name || "Choose.."}</option>
                    {patients?.data?.map((patient: any) => (
                      <option key={patient?.id} value={patient?.id}>
                        {patient?.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.clientName && (
                    <span className="text-danger">This field is required</span>
                  )}
                  <i className="icon position-absolute end-0 top-50 pe-2">
                    <FeatherIcons icon="chevron-down" />
                  </i>
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="position-relative mb-3" controlId="dob">
                <Form.Label>Date of birth </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="date"
                    defaultValue={patientInfo?.dob}
                    className="position-relative"
                  />
                  {/* {errors.dob && (
                    <span className="text-danger">This field is required</span>
                  )} */}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="position-relative mb-3">
                <Form.Label>Phone</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    defaultValue={patientInfo?.phone}
                    className="position-relative"
                  />
                  {/* {errors.paid && (
                    <span className="text-danger">This field is required</span>
                  )} */}
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col>
              <Form.Group className="position-relative mb-3">
                <Form.Label>Email</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    defaultValue={patientInfo?.email}
                    className="position-relative"
                  />
                  {/* {errors.discount && (
                    <span className="text-danger">This field is required</span>
                  )} */}
                </div>
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="position-relative mb-3" controlId="gender">
                <Form.Label>Gender</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    defaultValue={patientInfo?.gender}
                    className="position-relative"
                  />
                  {/* {errors.address && (
                    <span className="text-danger">This field is required</span>
                  )} */}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group
                className="position-relative mb-3"
                controlId="address"
              >
                <Form.Label>Address</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    defaultValue={patientInfo?.address}
                    className="position-relative"
                  />
                  {/* {errors.address && (
                    <span className="text-danger">This field is required</span>
                  )} */}
                </div>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="position-relative mb-3" controlId="doctor">
                <div className="d-flex justify-content-between">
                  <Form.Label>Doctor</Form.Label>
                  <Button
                    className="btn btn-warning btn-sm"
                    onClick={() => setLgShowDoctor(true)}
                  >
                    <i className="bi bi-exclamation-triangle me-1"></i>Not
                    Listed?
                  </Button>
                </div>
                <div className="d-flex">
                  <Form.Control
                    as="select"
                    {...register("doctor_id", { required: true })}
                    className="position-relative"
                  >
                    <option value="">Choose...</option>
                    {doctors?.map((doc: any) => (
                      <option key={doc?.id} value={doc?.id}>
                        {doc?.name}
                      </option>
                    ))}
                  </Form.Control>
                  {errors.doctor && (
                    <span className="text-danger">This field is required</span>
                  )}
                  <i className="icon position-absolute end-0 top-50 pe-2">
                    <FeatherIcons icon="chevron-down" />
                  </i>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Modal
        size="lg"
        show={lgShowDoctor}
        onHide={() => setLgShowDoctor(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Create Doctor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormProvider {...doctorFormMethods}>
            <Form onSubmit={handleDoctorSubmit(onSubmitDoctor)}>
              <Form.Group
                className="position-relative mb-3"
                controlId="doctorName"
              >
                <Form.Label>Name</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    {...registerDoctor("name", { required: true })}
                    placeholder="Doctor Name"
                    className="position-relative"
                  />
                  {doctorErrors.doctorName && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="position-relative mb-3"
                controlId="doctorInfo"
              >
                <Form.Label>Informations</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="text"
                    {...registerDoctor("informations", { required: true })}
                    placeholder="Doctor Info"
                    className="position-relative"
                  />
                  {doctorErrors.doctorInfo && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </Form.Group>
              <Form.Group
                className="position-relative mb-3"
                controlId="doctorCommission"
              >
                <Form.Label>Commission</Form.Label>
                <div className="d-flex">
                  <Form.Control
                    type="number"
                    step="0.01"
                    {...registerDoctor("commission", { required: true })}
                    placeholder="Commission"
                    className="position-relative"
                  />
                  {doctorErrors.doctorCommission && (
                    <span className="text-danger">This field is required</span>
                  )}
                </div>
              </Form.Group>
              <Button
                disabled={isCreatePending}
                variant="primary"
                type="submit"
              >
                {isCreateError
                  ? "Failed"
                  : isCreatePending
                  ? "Saving..."
                  : "Save"}
              </Button>
            </Form>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default NormalFormValidation;
