import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

// components

// images
import logoDark from "../assets/images/logo-dark.png";
import logoLight from "../assets/images/logo-light.png";
import PageTitle from "./PageTitle";

// invoice data
// const invoice = {
//   id: 5,
//   invoice_id: "#2024060624956",
//   invoice_date: "2022-09-11",
//   due_date: "-2.60",
//   customer: "Miracle Turner",
//   address: {
//     line_1: "123 Main St",
//     city: "San Francisco",
//     state: "CA",
//     zip: "94107",
//     phone: "(123) 456-7890",
//   },
//   items: [
//     {
//       name: "ipsum",
//       description: "neque",
//       hours: 1,
//       hour_rate: 72.89,
//       total: 72.89,
//     },
//   ],
//   sub_total: 72.89,
//   discount: 2.90,
//   total: 69.99,
//   notes: "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above.",
//   doctor_name: "Mrs. Eulah Wunsch",
//   gender: "male",
//   age: 12,
// };

const Invoice = ({ invoice }: any) => {
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Pages", path: "/pages/invoice" },
          { label: "Invoice", path: "/pages/invoice", active: true },
        ]}
        title={"Invoice"}
      />

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <div className="clearfix">
                <div className="float-sm-end">
                  <div className="auth-logo">
                    <Link to="/" className="logo logo-dark text-center">
                      <span className="logo-lg">
                        <img src={logoDark} alt="" height="24" />
                      </span>
                    </Link>
                    <Link to="/" className="logo logo-light text-center">
                      <span className="logo-lg">
                        <img src={logoLight} alt="" height="24" />
                      </span>
                    </Link>
                  </div>

                  {/* <address className="mt-2">
                    {invoice?.address?.line_1}
                    <br />
                    {invoice?.address?.city}, {invoice?.address?.state}{" "}
                    {invoice?.address?.zip}
                    <br />
                    <abbr title="Phone">P:</abbr> {invoice?.address?.phone}
                  </address> */}
                  <div style={{ textAlign: "right" }}>
                    <p>
                      795 Folsom Ave, Suite 600
                      <br />
                      San Francisco, CA 94107
                      <br />
                      P: (123) 456-7890
                    </p>
                  </div>
                </div>
                <div className="float-md-start">
                  <h4 className="m-0 d-print-none">Invoice</h4>
                  <dl className="row mb-2 mt-3">
                    <dt className="col-sm-3 fw-normal">Invoice Number :</dt>
                    <dd className="col-sm-9 fw-normal">
                      {invoice?.patient_code}
                    </dd>

                    <dt className="col-sm-3 fw-normal">Invoice Date :</dt>
                    <dd className="col-sm-9 fw-normal">{invoice?.date}</dd>

                    {/* <dt className="col-sm-3 fw-normal">Due Date :</dt>
                    <dd className="col-sm-9 fw-normal">{invoice?.due}</dd> */}
                  </dl>
                </div>
              </div>

              <Row className="mt-4">
                <Col md={6}>
                  <h6 className="fw-normal">Invoice For:</h6>
                  <h6 className="fs-16">{invoice.patient_name}</h6>
                  {/* <address>
                    {invoice?.address?.line_1}
                    <br />
                    {invoice?.address?.city}, {invoice?.address?.state}{" "}
                    {invoice?.address?.zip}
                    <br />
                    <abbr title="Phone">P:</abbr> {invoice?.phone}
                  </address> */}
                </Col>

                <Col md={6}>
                  <div className="text-md-end">
                    <h6 className="fw-normal">Total</h6>
                    <h2>${invoice?.total}</h2>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div className="table-responsive">
                    <table className="table mt-4 table-centered  table-nowrap">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Tests</th>
                          {/* <th style={{ width: "10%" }}>Hours</th>
                          <th style={{ width: "10%" }}>Hours Rate</th> */}
                          <th style={{ width: "10%" }} className="text-end">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(invoice?.tests_and_cultures || []).map(
                          (item: any, idx: number) => {
                            return (
                              <tr key={idx}>
                                <td>{idx + 1}</td>
                                <td>
                                  <h5 className="fs-16 mt-0 mb-2">
                                    {item?.name}
                                  </h5>
                                  <p className="text-muted mb-0">
                                    {item?.sample_type}
                                  </p>
                                </td>
                                {/* <td>{item?.hours}</td>
                                <td>${item?.hour_rate}</td> */}
                                <td className="text-end">${item?.price}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>

              <Row>
                {/* <Col sm={6}>
                  <div className="clearfix pt-5">
                    <h6 className="text-muted">Notes:</h6>
                    <small className="text-muted">{invoice?.notes}</small>
                  </div>
                </Col> */}
                <Col sm={6}>
                  <div className="float-end mt-4">
                    <p>
                      <span className="fw-medium">Sub-total :</span>
                      <span className="float-end">${invoice?.subtotal}</span>
                    </p>
                    <p>
                      <span className="fw-medium">Discount :</span>
                      <span className="float-end">
                        {" "}
                        &nbsp;&nbsp;&nbsp; ${invoice.discount}
                      </span>
                    </p>{" "}
                    <p>
                      <span className="fw-medium">paid :</span>
                      <span className="float-end"> ${invoice.paid}</span>
                    </p>
                    <h3>${invoice?.total} USD</h3>
                  </div>
                  <div className="clearfix"></div>
                </Col>
              </Row>

              <div className="mt-5 mb-1">
                <div className="text-end d-print-none">
                  <Link
                    to="#"
                    onClick={() => {
                      window.print();
                    }}
                    className="btn btn-primary me-1"
                  >
                    <i className="uil uil-print me-1"></i> Print
                  </Link>
                  <Link to="#" className="btn btn-info">
                    Submit
                  </Link>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Invoice;
