import React, { useState } from "react";
import { Accordion, Tab, Nav, Button, Spinner } from "react-bootstrap";
import "./TestsComponent.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { creaUpdateteItem, fetchItems } from "../../../utils/apiService";

interface CultureComponentProps {
  testData: any; // Array of tests
}

const CultureComponent: React.FC<CultureComponentProps> = ({ testData }) => {
  const [activeKey, setActiveKey] = useState("0");
  const queryClient = useQueryClient();
  const { handleSubmit } = useForm();
  const [comments, setComments] = useState<{ [key: string]: string }>({});
  const [cultureResults, setCultureResults] = useState<{
    [key: string]: any[];
  }>({});
  const [antibiotics, setAntibiotics] = useState<{ [key: string]: any[] }>({});

  const { data: antibioticOptions } = useQuery({
    queryKey: ["antibiotics"],
    queryFn: () => fetchItems("/antibiotics"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) =>
      creaUpdateteItem("/reports/results", testData?.id, newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["reports"] });
    },
  });

  const onSubmit = async () => {
    const activeTabIndex = parseInt(activeKey);
    const submitData = {
      culture_id: testData?.cultures[activeTabIndex].id,
      culture_result: cultureResults[activeTabIndex] || [],
      antibiotics: antibiotics[activeTabIndex] || [],
      comment: comments[activeTabIndex.toString()] || "",
    };

    console.log("Submitted Data:", submitData);
    // Perform submit action here
    CreateMutate(submitData as any);
  };
  // console.log(testData?.cultures);

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    testIndex: string
  ) => {
    setComments((prevComments) => ({
      ...prevComments,
      [testIndex]: event.target.value,
    }));
  };

  const handleCultureResultChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    testIndex: number,
    componentId: number,
    componentName: string
  ) => {
    setCultureResults((prevResults) => {
      const newResults = { ...prevResults };
      if (!newResults[testIndex]) {
        newResults[testIndex] = [];
      }
      const existingResultIndex = newResults[testIndex].findIndex(
        (result: any) => result.id === componentId
      );
      if (existingResultIndex > -1) {
        newResults[testIndex][existingResultIndex] = {
          ...newResults[testIndex][existingResultIndex],
          option: event.target.value,
        };
      } else {
        newResults[testIndex].push({
          id: componentId,
          name: componentName,
          option: event.target.value,
        });
      }
      return newResults;
    });
  };

  const handleAddAntibiotic = (testIndex: number) => {
    setAntibiotics((prevAntibiotics) => ({
      ...prevAntibiotics,
      [testIndex]: [
        ...(prevAntibiotics[testIndex] || []),
        { id: "", name: "", sensitivity: "" },
      ],
    }));
  };

  const handleAntibioticChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    testIndex: number,
    antibioticIndex: number,
    field: string
  ) => {
    const value = event.target.value;
    const updatedAntibiotics = { ...antibiotics };

    if (field === "name") {
      const [id, name] = value.split(",");
      updatedAntibiotics[testIndex][antibioticIndex].id = id;
      updatedAntibiotics[testIndex][antibioticIndex].name = name;
    } else {
      updatedAntibiotics[testIndex][antibioticIndex].sensitivity = value;
    }

    setAntibiotics(updatedAntibiotics);
  };

  return (
    <Accordion defaultActiveKey="0" className="custom-accordion">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Culture</Accordion.Header>
        <Accordion.Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Tab.Container
              defaultActiveKey="0"
              activeKey={activeKey}
              onSelect={(k) => setActiveKey(k || "0")}
            >
              <Nav variant="tabs" className="custom-tabs">
                {/* Dynamic Tabs */}
                {testData?.cultures?.map((test: any, testIndex: number) => (
                  <Nav.Item key={test.id}>
                    <Nav.Link eventKey={testIndex.toString()}>
                      {test.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              {/* Tabs content will be here */}
              <Tab.Content>
                {testData?.cultures?.map((test: any, testIndex: number) => (
                  <Tab.Pane eventKey={testIndex.toString()} key={testIndex}>
                    <div className="row">
                      {test?.components?.map((item: any, itemIndex: number) => (
                        <div className="col-md-4 mb-3" key={itemIndex}>
                          <label className="mb-2">{item?.name}</label>
                          <select
                            className="form-control"
                            onChange={(e) =>
                              handleCultureResultChange(
                                e,
                                testIndex,
                                item.id,
                                item.name
                              )
                            }
                          >
                            <option value={""}>choose...</option>
                            {item?.options?.map(
                              (option: any, optionIndex: number) => (
                                <option value={option} key={optionIndex}>
                                  {option}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      ))}
                    </div>
                    <div className="mb-3 mt-2 table-bordered">
                      <table className="table">
                        <thead>
                          <tr>
                            <th style={{ width: "40%" }}>Antibiotic</th>
                            <th style={{ width: "40%" }}>Sensitivity</th>
                            <th style={{ width: "20%" }}>
                              <Button
                                variant="primary"
                                onClick={() => handleAddAntibiotic(testIndex)}
                                style={{ width: "100%" }}
                              >
                                Add Antibiotic
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {antibiotics[testIndex]?.map(
                            (antibiotic: any, antibioticIndex: number) => (
                              <tr key={antibioticIndex}>
                                <td style={{ width: "40%" }}>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      handleAntibioticChange(
                                        e,
                                        testIndex,
                                        antibioticIndex,
                                        "name"
                                      )
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    {antibioticOptions?.data?.map(
                                      (item: any) => (
                                        <option
                                          key={item?.id}
                                          value={`${item?.id},${item?.name}`}
                                        >
                                          {item?.name}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </td>
                                <td style={{ width: "40%" }}>
                                  <select
                                    className="form-control"
                                    onChange={(e) =>
                                      handleAntibioticChange(
                                        e,
                                        testIndex,
                                        antibioticIndex,
                                        "sensitivity"
                                      )
                                    }
                                  >
                                    <option value="">Choose...</option>
                                    <option value="High">High</option>
                                    <option value="Moderate">Moderate</option>
                                    <option value="Resistant">Resistant</option>
                                  </select>
                                </td>
                                <td style={{ width: "20%" }}>
                                  <Button
                                    variant="danger"
                                    onClick={() =>
                                      setAntibiotics((prevState) => ({
                                        ...prevState,
                                        [testIndex]: prevState[
                                          testIndex
                                        ].filter(
                                          (_, index) =>
                                            index !== antibioticIndex
                                        ),
                                      }))
                                    }
                                    style={{ width: "100%" }}
                                  >
                                    Remove
                                  </Button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>

                    <textarea
                      className="form-control mt-3"
                      rows={4}
                      placeholder="Comments"
                      value={comments[testIndex.toString()] || ""}
                      onChange={(e) =>
                        handleCommentChange(e, testIndex.toString())
                      }
                      name={`comments-${testIndex}`}
                    />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>

            <Button type="submit" className="mt-3" disabled={isCreatePending}>
              {isCreateError ? (
                "Failed"
              ) : isCreatePending ? (
                <>
                  <Spinner animation="border" size="sm" /> Saving...
                </>
              ) : (
                "Save"
              )}
            </Button>
          </form>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default CultureComponent;
