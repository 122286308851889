import React from "react";

// components
import { Dropdown, Card, ButtonGroup } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
// import records from "./data";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchItems } from "../../../utils/apiService";
import TableSkeleton from "../../../components/TableSkeleton";

const columns = [
  {
    Header: "Barcode",
    accessor: "barcode",
  },
  {
    Header: "Client Code",
    accessor: "client_code",
  },
  {
    Header: "Client Name",
    accessor: "client_name",
  },
  {
    Header: "Gender",
    accessor: "gender",
  },
  {
    Header: "Age",
    accessor: "age",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Tests",
    accessor: "test",
    Cell: ({ row }: any) => {
      return (
        <ul
          style={{
            listStyleType: "disc",
            paddingLeft: 20,
            margin: 0,
            color: `${row?.original?.done ? "green" : "#4B4B5A"}`,
          }}
        >
          {row?.original?.tests?.map((test: any, index: any) => (
            <li key={index}>{test?.name}</li>
          ))}
        </ul>
      );
    },
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Done",
    accessor: "done",
    Cell: ({ value }: any) => {
      // console.log(value);

      return <p>{value ? "Yes" : "No"}</p>;
    },
  },
  {
    Header: "Signed",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ({ row }: any) => (
      <div>
        <Dropdown as={ButtonGroup} className="mt-2 me-1" align="end">
          <Dropdown.Toggle variant="primary" className="cursor-pointer">
            <i className="bi bi-gear"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#">
              <Link
                className="text-body"
                to={`/apps/reports/edit/${row?.original?.id}`}
              >
                {" "}
                <i className="bi bi-pencil-square"></i> Edit Report
              </Link>
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <i className="bi bi-trash-fill"></i> Delete
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <i className="bi bi-pen-fill"></i> Sign Report
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <i className="bi bi-eye-fill"></i> Show
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <i className="bi bi-upc-scan"></i> Print Barcode
            </Dropdown.Item>
            <Dropdown.Item href="#">
              <i className="bi bi-envelope-fill"></i> Send Report
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    ),
  },
];

const Reports = () => {
  const {
    data: reports,
    isError,
    isLoading,
  } = useQuery({
    queryKey: ["reports"],
    queryFn: () => fetchItems("/reports"),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });
  console.log(reports);

  const sizePerPageList = [
    {
      text: "10",
      value: 10,
    },
    {
      text: "25",
      value: 25,
    },
    {
      text: "All",
      value: reports?.length,
    },
  ];

  if (isError) {
    return <p>Failed to Fetch Data</p>;
  }
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          //   { label: "Branches", path: "/apps/calendar" },
          { label: "Reports", path: "/apps/reports", active: true },
        ]}
        title={"Reports"}
      />

      <Card>
        <Card.Body>
          {/* <Col className="text-sm-end mt-sm-0 mt-2">
            <Link to="add" className="btn btn-primary btn-md float-end">
              <i className="uil-plus me-1"></i>Add New
            </Link>
          </Col> */}
          {isLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns}
              data={reports}
              pageSize={10}
              sizePerPageList={sizePerPageList}
              isSortable={true}
              pagination={true}
              isSearchable={true}
            />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default Reports;
