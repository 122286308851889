import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkeleton = () => {
  return (
    <div>
      {/* <Skeleton /> */}
      <Skeleton
        highlightColor={"#b9b9b9"}
        style={{
          marginBottom: "0.6rem",
        }}
        height={30}
        count={5}
      />
    </div>
  );
};

export default TableSkeleton;
