import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
// import records from "./data";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createItem, fetchItem, updateItem } from "../../../utils/apiService";

// Components

const NormalFormValidation = ({ formData }: any) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (formData) {
      // Set default values
      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    }
  }, [formData, setValue]);

  const queryClient = useQueryClient();
  // create
  const {
    mutate: CreateMutate,
    isError: isCreateError,
    isPending: isCreatePending,
  } = useMutation({
    mutationFn: (newItem) => createItem("/branches", newItem),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["branches"] });
    },
  });

  // update
  const {
    mutate: upDateMutate,
    isError: isUpdateError,
    isPending: isUpdatePending,
  } = useMutation({
    mutationFn: (item) => updateItem("/branches", formData?.id, item),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["branches"] });
    },
  });

  const onSubmit = (data: any) => {
    console.log("Form  ", data);
    if (formData) {
      upDateMutate(data);
      return;
    } else {
      CreateMutate(data);
      return;
    }
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Row className="align-items-center">
              <Col className="mb-3">
                <Form.Group
                  className="position-relative"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    {...register("name", { required: "Name is required" })}
                    type="text"
                    placeholder="Enter First name"
                  />
                  {errors.name && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="mb-3">
                <Form.Group
                  className="position-relative"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                    })}
                    type="email"
                    placeholder="Enter Email"
                  />
                  {errors.email && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.email.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="mb-3">
                <Form.Group
                  className="position-relative"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    {...register("phone", { required: "Phone is required" })}
                    type="text"
                    placeholder="Enter Phone Number"
                  />
                  {errors.phone && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.phone.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="mb-3">
                <Form.Group
                  className="position-relative"
                  controlId="validationTooltip01"
                >
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    as="textarea"
                    {...register("address", {
                      required: "Address is required",
                    })}
                    style={{ height: "100px" }}
                    placeholder="Enter Address"
                  />
                  {errors.address && (
                    <Form.Control.Feedback type="invalid" tooltip>
                      {errors.address.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {formData ? (
              <Button
                disabled={isUpdatePending}
                variant="primary"
                type="submit"
              >
                {isUpdateError
                  ? "Failed"
                  : isUpdatePending
                  ? "Updating"
                  : "Update"}{" "}
              </Button>
            ) : (
              <Button
                disabled={isCreatePending}
                variant="primary"
                type="submit"
              >
                {isCreateError
                  ? "Failed"
                  : isCreatePending
                  ? "Creating..."
                  : "Create"}
              </Button>
            )}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

const AddBranch = () => {
  const { name } = useParams();
  const [editData, setEditData] = useState<any>(null);

  const {
    data: antibiotic,
    // isError,
    isLoading,
  } = useQuery({
    queryKey: [`branches/${name}`],
    queryFn: () => fetchItem("/branches", Number(name)),
    staleTime: 5 * 60 * 1000, // 5 minutes
    // cacheTime: 30 * 60 * 1000, // 30 minutes
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!name,
  });

  useEffect(() => {
    if (name && antibiotic) {
      setEditData(antibiotic);
    }
  }, [antibiotic, name]);

  return (
    <React.Fragment>
      <PageTitle
        breadCrumbItems={[
          { label: "Test", path: "/apps/test" },
          { label: "Create", path: "/apps/test/add", active: true },
        ]}
        title={"Create Branch"}
      />

      <Row>
        <Col lg={12}>
          {isLoading ? (
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <NormalFormValidation formData={editData} />
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default AddBranch;
