import { Row, Col, Card, Form } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle";
import Table from "../../../components/Table";
import { fetchItems } from "../../../utils/apiService";
import { useQuery } from "@tanstack/react-query";

interface Test {
  test_id: number; // Change id to test_id
  price: number;
}

interface Culture {
  culture_id: number; // Change id to culture_id
  price: number;
}

interface TestCultureProps {
  selectedTests: Test[];
  selectedCultures: Culture[];
  setSelectedTests: React.Dispatch<React.SetStateAction<Test[]>>;
  setSelectedCultures: React.Dispatch<React.SetStateAction<Culture[]>>;
}

const TestCulture: React.FC<TestCultureProps> = ({
  selectedTests,
  selectedCultures,
  setSelectedTests,
  setSelectedCultures,
}) => {
  const handleTestSelection = (test: any) => {
    const index = selectedTests.findIndex((item) => item.test_id === test.test_id); // Update id to test_id
    if (index === -1) {
      setSelectedTests([...selectedTests, { test_id: test.test_id, price: test.price }]); // Update id to test_id
    } else {
      const updatedTests = [...selectedTests];
      updatedTests.splice(index, 1);
      setSelectedTests(updatedTests);
    }
  };

  const handleCultureSelection = (culture: any) => {
    const index = selectedCultures.findIndex((item) => item.culture_id === culture.culture_id); // Update id to culture_id
    if (index === -1) {
      setSelectedCultures([
        ...selectedCultures,
        { culture_id: culture.culture_id, price: culture.price }, // Update id to culture_id
      ]);
    } else {
      const updatedCultures = [...selectedCultures];
      updatedCultures.splice(index, 1);
      setSelectedCultures(updatedCultures);
    }
  };

  const { data: tests, isLoading: isTestLoading } = useQuery({
    queryKey: ["test-prices"],
    queryFn: () => fetchItems("/test-prices"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const { data: cultures, isLoading: isCulLoading } = useQuery({
    queryKey: ["culture-prices"],
    queryFn: () => fetchItems("/culture-prices"),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const columns = [
    {
      Header: "Test Name",
      accessor: "test_name",
      Cell: ({ row }: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Check
            type="checkbox"
            id={`test-checkbox-${row?.original?.test_id}`} // Update id to test_id
            className="me-2"
            style={{ marginBottom: 3 }}
            checked={selectedTests.some((test) => test.test_id === row.original.test_id)} // Update id to test_id
            onChange={() => handleTestSelection(row.original)}
          />
          <label
            htmlFor={`test-checkbox-${row?.original?.test_id}`}
            className="mb-0"
          >
            {row?.original?.test_name}
          </label>{" "}
        </div>
      ),
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ value }: any) => `${value} USD`,
    },
  ];

  const cultureColumns = [
    {
      Header: "Culture Name",
      accessor: "culture_name",
      Cell: ({ row }: any) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Form.Check
            type="checkbox"
            id={`culture-checkbox-${row?.original?.culture_id}`} // Update id to culture_id
            className="me-2"
            style={{ marginBottom: 3 }}
            checked={selectedCultures.some((culture) => culture.culture_id === row.original.culture_id)} // Update id to culture_id
            onChange={() => handleCultureSelection(row.original)}
          />
          <label
            htmlFor={`culture-checkbox-${row?.original?.culture_id}`}
            className="mb-0"
          >
            {row?.original?.culture_name}
          </label>{" "}
        </div>
      ),
    },
    {
      Header: "Price",
      accessor: "price",
      Cell: ({ value }: any) => `${value} USD`,
    },
  ];

  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "All", value: tests ? tests.length : 0 },
  ];

  const cultureSizePerPageList = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "All", value: cultures ? cultures.length : 0 },
  ];

  return (
    <>
      <Card>
        <Card.Body>
          <Row className="align-items-center">
            <Col>
              <PageTitle breadCrumbItems={[]} title={"Tests"} />
              {isTestLoading ? (
                <p>Loading tests...</p>
              ) : (
                <Card>
                  <Card.Body>
                    <Table
                      columns={columns}
                      data={tests}
                      pageSize={10}
                      sizePerPageList={sizePerPageList}
                      isSortable={true}
                      pagination={true}
                      isSearchable={true}
                    />
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <PageTitle breadCrumbItems={[]} title={"Cultures"} />
              {isCulLoading ? (
                <p>Loading cultures...</p>
              ) : (
                <Card>
                  <Card.Body>
                    <Table
                      columns={cultureColumns}
                      data={cultures}
                      pageSize={10}
                      sizePerPageList={cultureSizePerPageList}
                      isSortable={true}
                      pagination={true}
                      isSearchable={true}
                    />
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default TestCulture;
