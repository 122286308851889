import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { fetchItem } from "../../../utils/apiService";
import { useParams } from "react-router-dom";
import PageTitle from "../../../components/PageTitle";
import { Card } from "react-bootstrap";
import TestsComponent from "./TestsComponent";
import CultureComponent from "./CultureComponent";

const EditReport = () => {
  const { id } = useParams();
  const [editData, setEditData] = useState<any>(null);

  const { data: reports, isLoading } = useQuery({
    queryKey: [`reports/${id}`],
    queryFn: () => fetchItem("/reports", id),
    staleTime: 5 * 60 * 1000,
    refetchInterval: 10 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!id,
  });

  useEffect(() => {
    if (id && reports) {
      setEditData(reports);
    }
  }, [reports, id]);

  // console.log(editData);

  return (
    <div>
      <PageTitle
        breadCrumbItems={[
          //   { label: "Branches", path: "/apps/calendar" },
          { label: "Edit Reports", path: "/apps/reports/eidt", active: true },
        ]}
        title={"Edit Reports"}
      />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Card>
            <Card.Body>
              <TestsComponent testData={editData} />
            </Card.Body>
          </Card>{" "}
          <Card>
            <Card.Body>
              <CultureComponent testData={editData} />
            </Card.Body>
          </Card>
        </>
      )}
    </div>
  );
};

export default EditReport;
